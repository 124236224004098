import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Modal, Row } from 'react-bootstrap';
import { isValidPhoneNumber } from 'libphonenumber-js';

import Button from 'components/shared/Button';
import Input from 'components/shared/Input';
import Select from 'components/shared/Select';

import { equipmentTypes } from 'components/globalInfo/equipment';

import { filterOptions } from 'utils/selectUtils';
import targetValue from 'utils/targetValue';
import actions from 'store/actions/DriverDetails';
import { resourceActions } from 'store/modules/resourceReducer/actions';
import {
  districtOptions,
  formattedDriverDistricts,
  formattedDriverContainers,
  equipmentOptions,
 } from './helpers';
import {
  useCarrierOptions,
  useContainerOptions,
} from './hooks';


const defaultOnBoardingDistrict = Number(
  // eslint-disable-next-line no-underscore-dangle
  window._env_.DEFAULT_ONBOARDING_DISTRICT,
);
const EditDriver = ({
  carrierId,
  getDistrictAccordingToCarrier,
  driverDistricts,
  setModalValue,
  showEditDriver,
  closeEditDriver,
  districts,
  editData,
  editDriver,
  closeModalEditInfo,
  requiredEquipment,
}) => {
  const {
    name,
    phone,
    email,
    carrierId: carrier,
    selectedDistricts,
    containers,
    truck,
    trailer,
    longitude,
    latitude,
    status,
    equipment,
    driverId,
  } = editData;

  const carrierOptions = useCarrierOptions();
  const districtIds = selectedDistricts?.length
    ? selectedDistricts.map(d => d.value)
    : [];
  const containerOptions = useContainerOptions(driverId, districtIds);
  const districtOptionsList = districtOptions(districts);
  if (!districtOptionsList.find(i => i.value === defaultOnBoardingDistrict)) {
    districtOptionsList.push({
      value: defaultOnBoardingDistrict,
      label: `${defaultOnBoardingDistrict} | On-boarding`,
    });
  }

  const validDriverDistricts = useMemo(
    () =>
      selectedDistricts
        ? selectedDistricts.filter(item =>
            districtOptionsList
              .map(district => district.value)
              .includes(item.value),
          )
        : [],
    [districtOptionsList, selectedDistricts],
  );

  const saveDisabled = useMemo(() => {
    if (
      !editData.name.trim().length ||
      !editData.phone.trim().length ||
      !editData.email.trim().length ||
      !carrierId ||
      !validDriverDistricts.length ||
      !editData.truck.trim().length ||
      !editData.trailer.trim().length ||
      !editData.longitude ||
      !editData.latitude ||
      !equipment ||
      !isValidPhoneNumber(editData.phone)
    ) {
      return true;
    }
    return false;
  }, [editData, validDriverDistricts, carrierId]);

  useEffect(() => {
    getDistrictAccordingToCarrier(carrierId);
    const setDistrictsValue = filterOptions(
      formattedDriverDistricts(driverDistricts),
      null,
      null,
      null,
      'label',
    );
    setModalValue(setDistrictsValue, 'selectedDistricts');
    setModalValue(formattedDriverContainers(containers), 'containers');
    return () => {
      closeModalEditInfo();
    };
  }, []);

  useEffect(() => {
    if (carrierId) {
      getDistrictAccordingToCarrier(carrierId);
    }
  }, [carrierId, getDistrictAccordingToCarrier]);

  return (
    <div className="inmodal">
      <Modal
        style={{ top: `${10}%` }}
        className="modal-container"
        show={showEditDriver}
        onHide={closeEditDriver}>
        <form
          onSubmit={editDriver}
          className="m-t"
          role="form"
          encType="multipart/form-data">
          <Modal.Header>
            <h3>Edit Driver</h3>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <div className="form-group has-feedback col-md-6">
                <Input
                  onChange={e => setModalValue(e, 'name')}
                  value={name}
                  label="Name"
                  required
                  testSelector="driver-details_edit_name_input"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Input
                  onChange={e => setModalValue(e, 'email')}
                  value={email}
                  label="Email"
                  type="email"
                  testSelector="driver-details_edit_email_input"
                  required
                />
              </div>
            </Row>
            <Row>
              <div className="form-group has-feedback col-md-6">
                <Input
                  type="tel"
                  onChange={e => setModalValue(e, 'phone')}
                  value={phone}
                  label="Country Code and Phone  Number"
                  required
                  testSelector="driver-details_edit_phone_input"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Select
                  placeholder="Carrier"
                  onChange={item => {
                    setModalValue(item.value, 'carrierId');
                    setModalValue('', 'districtId');
                    setModalValue('', 'truck');
                  }}
                  value={carrierOptions.find(item => item.value === carrier)}
                  options={carrierOptions}
                  required
                  isDisabled={!!status}
                  testSelector="driver-details_edit_carrier_select"
                />
              </div>
            </Row>
            <Row>
              <div className="form-group has-feedback col-md-6">
                <Input
                  onChange={e => setModalValue(e, 'truck')}
                  value={truck}
                  label="Truck number"
                  required
                  testSelector="driver-details_edit_truck_input"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Input
                  onChange={e => setModalValue(e, 'trailer')}
                  value={trailer}
                  label="Trailer number"
                  required
                  testSelector="driver-details_edit_trailer_input"
                />
              </div>
            </Row>
            <Row>
              <div className="form-group has-feedback col-md-6">
                <Input
                  type="number"
                  onChange={e => setModalValue(e, 'latitude')}
                  value={latitude}
                  label="Latitude"
                  required
                  testSelector="driver-details_edit_lat_input"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Input
                  type="number"
                  onChange={e => setModalValue(e, 'longitude')}
                  value={longitude}
                  label="Longitude"
                  required
                  testSelector="driver-details_edit_long_input"
                />
              </div>
            </Row>
            <Row>
              <div className="form-group has-feedback col-md-6">
                <Select
                  isMulti
                  placeholder="Districts"
                  onChange={item => {
                    setModalValue(item, 'selectedDistricts');
                    // If removing district, clear the containers and make user reselect container(s)
                      // because containers depend on districts on driver
                    if (item.length < validDriverDistricts.length) {
                      setModalValue([], 'containers');
                    }
                  }}
                  value={validDriverDistricts}
                  options={districtOptionsList}
                  required
                  testSelector="driver-details_edit_districts_select"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Select
                  placeholder="Equipment"
                  onChange={item => {
                    setModalValue(item.value, 'equipment');
                    if (!item.value !== 3) {
                      setModalValue([], 'containers');
                    }
                  }}
                  value={equipmentOptions(requiredEquipment).find(
                    item => item.value == equipment,
                  )}
                  options={equipmentOptions(requiredEquipment)}
                  required
                  testSelector="driver-details_edit-info_equipment_select"
                />
              </div>
            </Row>
            {equipment === equipmentTypes['Containerized'] && (
              <Row>
                <div className="form-group has-feedback col-md-6">
                  <Select
                    isMulti
                    placeholder="Container"
                    onChange={item => setModalValue(item, 'containers')}
                    value={containers}
                    options={containerOptions}
                    testSelector="driver-details_edit_containers_select"
                  />
                </div>
              </Row>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={closeEditDriver}
              colour="white"
              testSelector="driver-details_edit-driver_close_btn">
              Close
            </Button>
            <Button
              type="submit"
              disabled={saveDisabled}
              testSelector="driver-details_edit-driver_save_btn">
              Save
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

const mapStateToProps = state => ({
  editData: state.driverProfile.editInfo.editInfoDriver,
  carrierId: state.driverProfile.editInfo.editInfoDriver.carrierId,
  carriers: state.resourceReducer.carriers.carriers,
  isLoadedCarriers: state.resourceReducer.carriers.isLoadedCarriers,
  districts: state.resourceReducer.districtToCarrierId.districts,
  requiredEquipment: state.lookUp.equipmentRequired,
  isLoadedDistrictsToCarrier:
    state.resourceReducer.districtToCarrierId.isLoadedDistrictsToCarrier,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  closeModalEditInfo: () => dispatch(actions.closeModalEditInfo()),
  getDistrictAccordingToCarrier: carrierId =>
    dispatch(resourceActions.getDistrictAccordingToCarrier(carrierId)),
  setModalValue: (e, name) => {
    dispatch(actions.setEditDriverValue(targetValue(e), name));
  },
  editDriver: e => {
    e.preventDefault();
    dispatch(actions.editDriver(ownProps.closeEditDriver));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditDriver);
