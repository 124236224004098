import authService from 'services/auth/service';

import { useCarriers } from 'api/v2/carriers';
import { useContainers } from 'api/v2/containers';
import {
  carrierOptions,
  containerOptions
} from './helpers';


const user = authService.getUser();

export const useCarrierOptions = () => {
  const { data: carriers } = useCarriers();
  return carrierOptions(carriers, user);
};

export const useContainerOptions = (driverId, districtIds) => {
  const { data: containers } = useContainers(driverId, districtIds);
  return containerOptions(containers);
}