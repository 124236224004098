export const districtOptions = districts => {
  if (districts && districts.length) {
    return districts.map(district => ({
      value: district.id,
      label: `#${district.id} | ${district.name}`,
    }));
  }
  return [];
};

export const formattedDriverDistricts = driverDistricts =>
  driverDistricts.map(district => {
    district.label = `#${district.id} | ${district.name}`;
    return district;
  });

export const carrierOptions = (carriers, user) => {
  if (user && user.entityType === 3) {
    return (carriers || [])
      .filter(c => c.id === user.entityId)
      .map(item => ({
        value: item.id,
        name: item.name,
        label: `${item.id} | ${item.name}`,
      }));
  }
  return (carriers || []).map(item => ({
    value: item.id,
    name: item.name,
    label: `${item.id} | ${item.name}`,
  }));
};

export const equipmentOptions = requiredEquipment => {
  return requiredEquipment.map(equipmentItem => {
    return {
      value: equipmentItem.id,
      label: equipmentItem.title,
    };
  });
};

export const containerOptions = containers => {
  return containers.map(container => ({
    value: container.containerId,
    label: `${container.customerName} - ${container.containerNumber}`
  }));
}

export const formattedDriverContainers = driverContainers =>
  driverContainers.map(d => ({
    value: d.containerId,
    label: `${d.customerName} - ${d.containerNumber}`
  }));