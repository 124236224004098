import swal from 'bootstrap-sweetalert';
import moment from 'moment';

import { driver } from 'services/driver/service';
import { carrier } from 'services/carrier/service';
import authService from 'services/auth/service';
import { userRole } from 'components/globalInfo/userRoles';

const getDriverProfile = id => {
  const request = () => ({ type: 'GET_DRIVER_PROFILE_REQUEST' });
  const success = driverInf => ({
    type: 'GET_DRIVER_PROFILE_SUCCESS',
    driverInf,
  });
  const failure = error => ({
    type: 'GET_DRIVER_PROFILE_FAILURE',
    error,
  });
  return dispatch => {
    dispatch(request());
    driver
      .getDriverProfile(id)
      .then(response => {
        dispatch(success(response.data.data));
      })
      .catch(error => {
        // if (error.data.status === 400) {
        //   dispatch(success(error.data.data));
        // } else {
        dispatch(failure(error));
        // }
      });
  };
};

const clearStore = () => ({ type: 'CLEAR_STORE_DRIVER_PROFILE' });

const getDriverCertificates = id => {
  const request = () => ({ type: 'GET_DRIVER_CERTIFICATES_REQUEST' });
  const success = certificates => ({
    type: 'GET_DRIVER_CERTIFICATES_SUCCESS',
    certificates,
  });
  const failure = error => ({
    type: 'GET_DRIVER_CERTIFICATES_FAILURE',
    error,
  });
  return dispatch => {
    dispatch(request());
    driver
      .getDriverCertificates(id)
      .then(response => {
        dispatch(success(response.data.data.certificates));
      })
      .catch(error => {
        dispatch(failure(error));
      });
  };
};

const setDriverData = data => ({
  type: 'SET_DRIVER_PROFILE_DATA',
  data,
});

const editInfo = closeModal => (dispatch, getState) => {
  const {
    driverProfile: {
      editInfo: {
        editInfoDriver: {
          districtId,
          homeDistrict,
          phone,
          email,
          equipment,
          driverId,
          selectedDistricts,
        },
      },
    },
  } = getState();

  const params = {
    contactNo: phone.replace(/\s+/g, ''),
    districtId: districtId || selectedDistricts[0].value,
    driverId,
    email,
    equipment,
    homeDistrict,
  };

  driver
    .editDriver(params)
    .then(response => {
      const driverDistricts = selectedDistricts.map(district => district.value);
      driver
        .changeDistricts(driverDistricts, driverId)
        .then(resp => {
          swal(resp?.data?.message || 'Success', '', 'success');
          dispatch(getDriverProfile(driverId));
          closeModal();
        })
        .catch(error => {
          if (error.isAxiosError) {
            swal(
              error.response.data?.message,
              'Cannot Change This Districts',
              'error',
            );
          } else {
            swal(
              error.message || 'Cannot Change This District',
              'Cannot Change This District',
              'error',
            );
          }
        });
    })
    .catch(error => {
      swal(error?.data?.message || 'Error Editing Driver', '', 'error');
    });
};

const closeModalEditInfo = () => ({ type: 'CLOSE_MODAL_EDIT_INFO' });

const changeDriverRate = closeModal => (dispatch, getState) => {
  const {
    driverProfile: {
      editInfo: {
        editInfoDriver: { driverId, comment, rating },
      },
    },
  } = getState();
  const params = { comment, rating };
  driver
    .changeDriverRate(driverId, params)
    .then(response => {
      dispatch(getDriverProfile(driverId));
      swal(response.data.message, '', 'success');
      closeModal();
    })
    .catch(error => {
      swal(error.response.data.message, '', 'error');
    });
};

const changeDriverTruck = closeModal => (dispatch, getState) => {
  const {
    driverProfile: {
      editInfo: {
        editInfoDriver: { driverId, truck, trailer, equipment, container },
      },
    },
  } = getState();
  const params = {
    containerNumber: container,
    equipmentType: equipment,
    trailerNumber: trailer,
    truckNumber: truck,
  };
  driver
    .editTruck(driverId, params)
    .then(response => {
      dispatch(getDriverProfile(driverId));
      swal(response.data.message, '', 'success');
      closeModal();
    })
    .catch(error => {
      swal(error.response.data.message, '', 'error');
    });
};

const changePercentage = closeModal => (dispatch, getState) => {
  const {
    driverProfile: {
      editInfo: {
        editInfoDriver: { driverId, percentage },
      },
    },
  } = getState();
  driver
    .changePercentage(driverId, percentage)
    .then(response => {
      dispatch(getDriverProfile(driverId));
      swal(response.data.message, '', 'success');
      closeModal();
    })
    .catch(error => {
      swal(error.response.data.message, '', 'error');
    });
};

const setEditDriverValue = (value, name) => dispatch => {
  dispatch({ type: 'MODAL_EDIT_DRIVER_VALUE', value, name });
};

const editDriver = closeModal => (dispatch, getState) => {
  const {
    driverProfile: {
      editInfo: {
        editInfoDriver: {
          carrierId,
          districtId,
          homeDistrict,
          phone,
          email,
          truck,
          trailer,
          longitude,
          latitude,
          name,
          driverId,
          equipment,
          selectedDistricts,
          containers
        },
      },
    },
  } = getState();
  const params = {
    name,
    email,
    carrierId,
    driverId,
    district: districtId || selectedDistricts[0].value,
    equipment: equipment,
    homeDistrict,
    lat: latitude,
    long: longitude,
    vehicleNumber: truck,
    trailerNumber: trailer,
    contactNo: phone.replace(/\s+/g, ''),
    containers: containers.length
      ? containers.map(c => Number(c.value))
      : []
  };

  driver
    .editAllDriver(params)
    .then(response => {
      const driverDistricts = selectedDistricts.map(district => district.value);
      driver
        .changeDistricts(driverDistricts, driverId)
        .then(resp => {
          dispatch(getDriverProfile(driverId));
          swal(response?.data?.message || 'Success', '', 'success');
          closeModal();
        })
        .catch(error => {
          if (error.isAxiosError) {
            swal(
              error.response.data?.message,
              'Cannot Change This Districts',
              'error',
            );
          } else {
            swal(
              error.message || 'Cannot Change This District',
              'Cannot Change This District',
              'error',
            );
          }
        });
    })
    .catch(error => {
      swal(error.response.data.message, '', 'error');
    });
};

const initDriverChat = (chatId, driverId) => (dispatch, getState) => {
  const init = (chatId, driverId) => ({
    type: 'DRIVER_DETAILS_INIT_CHAT',
    chatId,
    driverId,
  });
  dispatch(init(chatId, driverId));
};

const saveMessages = message => (dispatch, getState) => {
  const save = messages => ({
    type: 'DRIVER_DETAILS_SAVE_MESSAGGES',
    messages,
  });
  const state = getState();
  const messages = [...state.driverProfile.chat.messages];
  messages.push(message);
  dispatch(save(messages));
};

const scrollToBottom = () => {
  setTimeout(() => {
    const block = document.querySelector('.chat__discussion');
    if (block) {
      block.scrollTop = block.scrollHeight;
    }
  }, 0);
};

const destroyChat = () => dispatch => { };

const sendMessage = () => (dispatch, getState) => {
  const send = () => ({ type: 'DRIVER_DETAILS_SEND_MESSAGGES' });
  const state = getState();
  const { chat } = state.driverProfile;
  const { isFile } = chat;
  const pushMessage = () => {
    dispatch(send());
  };
  if (!isFile) {
    pushMessage();
  }
};
const setChatInputValue = (value, name) => ({
  type: 'DRIVER_DETAILS_SET_CHAT_INPUT_VALUE',
  value,
  name,
});
const setChatFile = file => ({ type: 'DRIVER_DETAILS_SET_FILE', file });
const unsetChatFile = () => ({ type: 'DRIVER_DETAILS_UNSET_FILE' });

// END CHAT

// change districts
const setValue = value => ({ type: 'DRIVER_DETAILS_SELECT_DISTRICT', value });

const initChangeDistricts = () => (dispatch, getState) => {
  const save = (districts, selectedDistricts) => ({
    type: 'DRIVER_DETAILS_SET_CARRIER_DISTRICTS',
    districts,
    selectedDistricts,
  });
  const state = getState();
  const {
    driverProfile: {
      driver: {
        driverInf: { driver, districts: selectedDistricts },
      },
    },
  } = state;

  carrier
    .getDistrictAccordingToCarrier(driver[0].carrier_id)
    .then(msg => {
      const districts = msg.data.data.district;
      const selected = selectedDistricts.map(item => ({
        value: item.id,
        label: item.name,
      }));
      dispatch(save(districts, selected));
    })
    .catch(error => swal(error.response.data.message, '', 'error'));
};

const submitSave = closeModal => (dispatch, getState) => {
  const success = () => ({ type: 'DRIVER_DETAILS_SET_DISTRICT_SUCCESS' });
  const state = getState();
  const {
    driverProfile: {
      driver: {
        driverInf: { driver: driverInfo },
      },
      districts: { selectedDistricts },
    },
  } = state;
  const districtIds = selectedDistricts.map(item => item.value);
  driver
    .changeDistricts(districtIds, driverInfo[0].id)
    .then(msg => {
      dispatch(success());
      swal('Success!', '', 'success');
      dispatch(getDriverProfile(driverInfo[0].id));
      closeModal();
    })
    .catch(error => {
      swal(
        error?.data?.message || 'Cannot Change This District',
        'Failed to change Districts',
        'error',
      );
    });
};

const MYcsvmaker = (data, doesCommasNeedToBeSanitized = false) => {
  const csv = [[Object.keys(data[0])]];
  for (let i = 0; i < data.length; i++) {
    csv.push([Object.values(data[i])]);
  }
  if (doesCommasNeedToBeSanitized) {
    for (let i = 0; i < csv.length; i++) {
      for (let j = 0; j < csv[i].length; j++) {
        for (let k = 0; k < csv[i][j].length; k++) {
          if (
            typeof csv[i][j][k] === 'string' ||
            csv[i][j][k] instanceof String
          ) {
            csv[i][j][k] = csv[i][j][k].replaceAll(',', '');
          }
        }
      }
    }
  }
  return csv.map(row => row.join(',')).join('\n');
};

const downloadDriverRejections = driverId => async (dispatch, getState) => {
  const rejectionReportData = await driver
    .rejectDriverReport(driverId)
    .then(res => res.data.data.rejectedOrders)
    .catch(error => {
      swal(
        error?.data?.message || 'Cannot Download Driver Rejections',
        'Failed to retrieve data.',
        'error',
      );
    });
  const testData = MYcsvmaker(rejectionReportData, true);
  const hiddenElement = document.createElement('a');
  const blob = new Blob([testData], {
    type: 'application/csv;charset=utf-8',
  });
  hiddenElement.href = window.URL.createObjectURL(blob);
  hiddenElement.download = `driver${driverId}Rejection.csv`;
  hiddenElement.click();
};

const actions = {
  getDriverProfile,
  getDriverCertificates,
  setDriverData,
  editInfo,
  closeModalEditInfo,
  changeDriverRate,
  changeDriverTruck,
  clearStore,
  changePercentage,
  downloadDriverRejections,
  editDriver,
  setEditDriverValue,
  initDriverChat,
  saveMessages,
  scrollToBottom,
  destroyChat,
  sendMessage,
  setChatInputValue,
  setChatFile,
  unsetChatFile,
  initChangeDistricts,
  submitSave,
  setValue,
};

export default actions;
